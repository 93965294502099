// BaseTable.js
import React, { useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TablePagination,
  Typography,
} from "@mui/material";
import { connect } from "react-redux";
import Filter from "./Filter";

const BaseTable = ({
  rows,
  fetchData,
  isLoading,
  headers,
  renderRows,
  showPagination,
  paginationParams,
  setPaginationParams,
  currentPage,
  limit,
  isFilter,
  filters,
  handleFilterClick,
  height,
}) => {


  useEffect(() => {
    if (currentPage && limit) {
      const query = buildQuery(filters);
      fetchData(query);
    } else {
      fetchData();
    }
  }, [currentPage, limit, fetchData, filters]);

  const buildQuery = (filters) => {
    let query = "";

    // Add filter values to the query
    const activeFilters = filters.filter(
      (filter) =>
        filter.value &&
        (Array.isArray(filter.value)
          ? filter.value.length > 0
          : filter.value !== "")
    );

    // Build the filter part of the query first
    const filterQueryParts = [];
    activeFilters.forEach((filter) => {
      if (Array.isArray(filter.value) && filter.value.length > 0) {
        filterQueryParts.push(`${filter.name}=${filter.value.join(",")}`);
      } else if (filter.value && filter.value !== "") {
        filterQueryParts.push(`${filter.name}=${filter.value}`);
      }
    });

    // Determine the starting part of the query
    if (filterQueryParts.length > 0) {
      // If there are filters, add them first
      query += "?" + filterQueryParts.join("&");
    }

    // Add pagination parameters if filters were added
    if (filterQueryParts.length > 0 && currentPage) {
      query += `&page=${currentPage}`;
    }
    if (filterQueryParts.length > 0 && limit) {
      query += `&pageSize=${limit}`;
    }

    // If there are no filters, only pagination parameters should be included
    if (filterQueryParts.length === 0) {
      if (currentPage) {
        query += `?page=${currentPage}`;
      }
      if (limit) {
        query += `&pageSize=${limit}`;
      }
    }

    return query;
  };

  const handlePageChange = (event, newPage) => {
    setPaginationParams((prevState) => ({
      ...prevState,
      currentPage: newPage + 1,
    }));
  };


  const handleRowsPerPageChange = (event) => {
    setPaginationParams((prevState) => ({
      ...prevState,
      limit: parseInt(event.target.value, 10),
      currentPage: 1,
    }));
  };

  return (
    <Paper>
      {isFilter && (
        <Filter filterDataArr={filters} handleFilterClick={handleFilterClick} />
      )}
      <TableContainer
        component={Paper}
        sx={{
          // backgroundColor: "#257076",
          // backgroundColor: "#0f1c1e",
          border: "1px solid #273334",
          // color: "#fff",
          maxHeight: height || "380px",
          "& th": {
            color: "#fff",
          },
        }}
      >
        <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow className="headerBorder">
              {headers &&
                headers?.map((header, index) => (
                  <TableCell
                    key={index}
                    align={header.align}
                  // sx={{
                  //   position: "sticky",
                  //   top: 0,
                  //   backgroundColor: "#0f1c1e",
                  //   zIndex: 1,
                  // }}
                  >
                    {header.label}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              color: "#fff",
              overflowY: "auto",
              maxHeight: "calc(500px - 98px)",
            }}
          >
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={headers?.length} align="center">
                  <h1>Loading...</h1>
                </TableCell>
              </TableRow>
            ) : rows?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={headers?.length} align="center">
                  <Typography>No data found</Typography>
                </TableCell>
              </TableRow>
            ) : (
              renderRows()
            )}
          </TableBody>
        </Table>

      </TableContainer>
      {showPagination && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={paginationParams?.totalRecords || 0}
          rowsPerPage={limit || 0}
          page={(currentPage || 1) - 1}
          onPageChange={handlePageChange}
          onRowsPerPageChange={handleRowsPerPageChange}
        />

      )}
    </Paper>
  );
};

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps)(BaseTable);
