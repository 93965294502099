import React, { useEffect } from "react";
import { Routes, Route, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import { updateUserData } from "../redux/action/user";
import Login from "../pages/login";
import UserManagementList from "../pages/userManagement/userManagementList";
import Dashboard from "../pages/Dashboard";
import Contents from "../pages/contents";
import Setting from "../pages/Setting/Setting";
import ProtectedRout from "./ProtectedRout";
import Determinants from "../pages/determinants/Determinants";
import Help from "../pages/help/Help";
import ContentDetails from "../pages/contentDetails/ContentDetail";
import DeterminantsDetail from "../pages/DeterminantsDetail/DeterminantsDetail";
import CreateContent from "../pages/createContent/createContent";
import ProgramSubscriptionListing from "../pages/ProgramSubscriptions/ProgramSubscriptionListing";
import AddProgram from "../pages/ProgramSubscriptions/AddProgram";
import { toast } from "react-toastify";


const decodeJWT = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    return JSON.parse(atob(base64));
  } catch (error) {
    return null;
  }
};

const RouteContainer = (props) => {
  const navigate = useNavigate();
  const token = localStorage.getItem('authToken');

  useEffect(() => {
    if (token) {
      const decodedToken = decodeJWT(token);
      if (decodedToken) {
        const currentTime = Math.floor(Date.now() / 1000);

        if (decodedToken.exp < currentTime) {
          toast.error("Your session is expired");
          localStorage.clear();
          navigate('/login');
        }
      }
    }
  }, [token, navigate]);

  return (
    <div>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="" element={<ProtectedRout />}>
          <Route path="/user_management_list" element={<UserManagementList />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/content/:Under_Review" exact element={<Contents />} />
          <Route path="/Setting" exact element={<Setting />} />
          <Route path="/determinants" exact element={<Determinants />} />
          <Route path="/help" exact element={<Help />} />
          <Route path="/content_details/:id/:activeTabName" element={<ContentDetails />} />
          <Route path="/determinants_details/:id" exact element={<DeterminantsDetail />} />
          <Route path="/content/create_new_content" exact element={<CreateContent />} />
          <Route path="/programs" exact element={<ProgramSubscriptionListing />} />
          <Route path="/programs/:id" exact element={<AddProgram />} />
        </Route>
      </Routes>
    </div>
  );
};

RouteContainer.propTypes = {};
RouteContainer.defaultProps = {};

const actionCreators = {
  updateUserData,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators
)(RouteContainer);
