import React, { useEffect } from "react";
import RouteContainer from "./routeContainer/RouteContainer";
import { BrowserRouter, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import './App.scss';
import Stack from '@mui/material/Stack';
import { connect } from 'react-redux';
import { getUser } from "./redux/action/user";


function App(props) {
  const verify = localStorage.getItem('isLogin');

  useEffect(() => {
    verify === "true" && props.getUser();
  }, [verify])

  return (
    <div>
      <Stack >
        <BrowserRouter>
          <ToastContainer />
          <RouteContainer />
        </BrowserRouter>
      </Stack>

    </div>
  );
}

App.propTypes = {};
App.defaultProps = {};

const actionCreators = {
  getUser
};

export default connect(
  ({ user, }) => ({
  }),
  actionCreators
)(App);
