import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
} from "react";
import { connect } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { Box, Typography } from "@mui/material";
import { getPaymentHistory } from "../../../redux/action/user";

const PaymentHistory = forwardRef((props, ref) => {
  useEffect(() => {
    props.getPaymentHistory();
  }, []);

  useImperativeHandle(ref, () => ({
    handleBackButton: () => props.setActiveAboutInfo("aboutDetails"),
  }));

  const isPlanActive = (startDate, endDate) => {
    if (endDate === null) {
      return "Life Time Access";
    }

    const start = startDate && new Date(startDate);
    const end = endDate && new Date(endDate);
    const today = new Date();

    if (today >= start && today <= end) {
      return "Active";
    }

    return "Inactive";
  };

  return (
    <div >
      <Typography sx={{ color: "#000", fontWeight: 600 }}>
        Payment History
      </Typography>
      <TableContainer
        sx={{
          border: "2px solid #e7fbfd",
          // borderRadius: "15px",
          backgroundColor: "#e7fbfd",
          p: 0,
          width: "auto",
        }}
      >
        <Table
          sx={{ backgroundColor: "transparent" }}
          aria-label="simple table"
        >
          <TableHead>
            <TableRow>
              <TableCell
                style={{
                  color: "#e29c2e",
                  fontWeight: 800,
                  fontSize: "17px",
                }}
              >
                Program Name
              </TableCell>
              <TableCell
                align="center"
                style={{
                  color: "#e29c2e",
                  fontWeight: 800,
                  fontSize: "17px",
                }}
              >
                Price
              </TableCell>
              <TableCell
                align="center"
                style={{
                  color: "#e29c2e",
                  fontWeight: 800,
                  fontSize: "17px",
                }}
              >
                Purchased Date
              </TableCell>
              <TableCell
                align="center"
                style={{
                  color: "#e29c2e",
                  fontWeight: 800,
                  fontSize: "17px",
                }}
              >
                Expiry Date
              </TableCell>
              <TableCell
                align="center"
                style={{
                  color: "#e29c2e",
                  fontWeight: 800,
                  fontSize: "17px",
                }}
              >
                Status
              </TableCell>
            </TableRow>
          </TableHead>
          {props.user.payHistoryLoading ? (
            <h1>Loading ...</h1>
          ) : (
            <TableBody>
              {props.user.paymentHistoryList &&
                props.user.paymentHistoryList.length > 0 ? (
                props.user.paymentHistoryList.map((data) => (
                  <TableRow key={data.ID}>
                    <TableCell
                      component="th"
                      scope="row"
                      style={{ color: "whitesmoke" }}
                    >
                      {data?.js_programSubscription &&
                        data?.js_programSubscription?.name}
                    </TableCell>
                    <TableCell align="center" style={{ color: "whitesmoke" }}>
                      {`$ ${data?.amount && data?.amount} ${data?.currency && data?.currency?.toUpperCase()
                        } `}
                    </TableCell>
                    <TableCell align="center" style={{ color: "whitesmoke" }}>
                      {data?.createdAt &&
                        new Date(data?.createdAt).toLocaleDateString()}
                    </TableCell>
                    <TableCell align="center" style={{ color: "whitesmoke" }}>
                      {(data?.expirationTime &&
                        (data?.expirationTime === null
                          ? "Not Applicable"
                          : new Date(
                            data?.expirationTime
                          ).toLocaleDateString())) ||
                        "Not Applicable"}
                    </TableCell>
                    <TableCell align="center" style={{ color: "whitesmoke" }}>
                      {isPlanActive(data?.createdAt, data?.expirationTime)}
                    </TableCell>
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={5}>
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        textAlign: "center",
                        color: "white",
                        height: "100px",
                      }}
                    >
                      <Typography variant="h6">No Records Found</Typography>
                    </Box>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </div>
  );
});

PaymentHistory.propTypes = {};
PaymentHistory.defaultProps = {};

const actionCreators = {
  getPaymentHistory,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators,
  null,
  { forwardRef: true }
)(PaymentHistory);
