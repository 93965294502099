import {
    TimerOutlined,
    Search,
    Close,
    ThumbDown,
    ThumbUp,
    MailOutline,
    ReceiptLong,
    WorkHistory
} from "@mui/icons-material";


export const IconPath = {
    TimerOutlined,
    Search,
    Close,
    ThumbDown,
    ThumbUp,
    EMail: MailOutline,
    ReceiptLong,
    WorkHistory


}
