import React, { useState } from 'react';
import './confirmPassword.scss';
import { connect } from 'react-redux';
import { forgotPasswordSubmitApi, updateUserData } from '../../redux/action/user'
import TextBox from '../../atoms/textbox';
import ButtonInput from '../../atoms/button';
import passwordCheck from '../../assets/images/password-check.png';
import backButton from '../../assets/images/arrow-left.svg';
import { CircularProgress } from '@mui/material';
import { toast } from 'react-toastify';

const ConfirmPassword = (props) => {
    const [loading, setLoading] = useState(false);
    const passwordChange = (value) => {
        props.updateUserData({
            ...props.user,
            password: value
        })
    }

    const confirmPasswordChange = (value) => {
        props.updateUserData({
            ...props.user,
            confirmPassword: value
        })
    }

    const gotoOTP = () => {
        props.updateUserData({
            ...props.user,
            status: 'LoginForgotPassword'
        })
    }

    const handleForgotPasswordSubmit = async () => {
        if (props.user?.password?.length <= 4 || props?.user?.confirmPassword?.length <= 4) {
            toast.error("Password length must be more than 4 charecters!!");
            return;
        } else if (props.user?.password !== props?.user?.confirmPassword) {
            toast.error("Password and confirm password didn't match");
            return;
        }
        const payload = {
            email: props.user?.userName,
            password: props.user?.password,
            passwordConfirm: props?.user?.confirmPassword
        }
        setLoading(true);
        try {
            const res = await forgotPasswordSubmitApi(payload)
            toast.success(res?.data?.message);
            props.updateUserData({
                ...props.user,
                status: 'login',
                isNewUser: true,
            })
        } catch (error) {
            toast.success(error?.response?.data?.message)
        } finally {
            setLoading(false);
        }
    }

    return (
        <div className="login_form">
            <div className="container-fluid">
                <div className="row">
                    <h2 className='title'>You're almost there!</h2>
                    <p className='subTitle'>
                        Start resetting your password
                    </p>
                </div>
                <TextBox
                    placeholder="Password"
                    type="password"
                    value={props.user?.password}
                    valueChange={passwordChange}
                    prefix={passwordCheck}
                />
                <TextBox
                    placeholder="Confirm Password"
                    type="password"
                    value={props.user?.confirmPassword}
                    valueChange={confirmPasswordChange}
                    prefix={passwordCheck}
                />
                <div>
                    <ButtonInput
                        text={
                            loading
                                ? <CircularProgress size={25} color="success" />
                                : 'SUBMIT'
                        }
                        onSubmit={handleForgotPasswordSubmit}
                        disabled={loading}
                    />
                    <ButtonInput text="Back" class="back-button" onSubmit={gotoOTP} iCon={backButton} />
                </div>
            </div>
        </div>
    );
};

ConfirmPassword.propTypes = {};
ConfirmPassword.defaultProps = {};

const actionCreators = {
    updateUserData
};

export default connect(
    ({ user }) => ({
        user,
    }),
    actionCreators
)(ConfirmPassword);