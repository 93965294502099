import React, { useState, useEffect } from "react";
import "./setting.scss";
import leftSettingImage from "../../assets/images/profile-logo.png";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch, { SwitchProps } from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import { connect } from "react-redux";
import { capitalizeFirstLowercaseRest } from "../../constants/constant";

import Hoc from "../../component/HOC";
import { modelToggle } from "../../redux/action/user";
import DialogView from '../../component/Model/Dialog';

const Setting = (props) => {
  const handleClose = () => {
    props.modelToggle(false, "edit_model");
  };

  const EditModel = () => {
    props.modelToggle(true, "edit_model");
  }

  const AddThresolValue = () => {
    props.modelToggle(true, "add_thresol_value");
  }

  const SetDuration = () => {
    props.modelToggle(true, "set_duration");
  }

  const IOSSwitch = styled((props) => (
    <Switch
      focusVisibleClassName=".Mui-focusVisible"
      disableRipple
      {...props}
    />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 0,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 2,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor:
            theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color:
          theme.palette.mode === "light"
            ? theme.palette.grey[100]
            : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));
  return (
    <Hoc activeMenu="Settings" subTitle={'Looks, like you have more updates'}>
      <div className="settingProfileSection">
        <DialogView open={props?.modelToggleData?.toggle} onClose={handleClose} role={'Profile'} buttonLabel={'UPDATE'} />

        <div className="leftSideProfile">
          <div className="imageWrap">
            <img src={leftSettingImage} alt="Company Logo" />
          </div>
          <div className="profileNameSetting">
            <h4 className="avenir-bold mb-0">{capitalizeFirstLowercaseRest(props?.currentUser?.data?.contentUser?.Fullname ? props?.currentUser?.data?.contentUser?.Fullname : '...')}</h4>
            <p className="mb-0">{props?.currentUser?.data?.contentUser?.EmailID ? props?.currentUser?.data?.contentUser?.EmailID : '...'}</p>
          </div>
        </div>
        <div className="rightSideProfile">
          <button onClick={EditModel} className="avenir-bold editBtnProfile">
            {/* <img src={require("../../assets/images/x.png")} />  */}
            Edit
          </button>
        </div>
      </div>
      <h4 className="titleHead avenir-bold">Content</h4>
      <div className="contentSection">
        <div className="contentList">
          <div className="leftSideContentList">
            <h6>Approved content Threshold</h6>
            <p>
              Specify the number of approved content that will go in every
              subcategories.
            </p>
          </div>
          <div onClick={() => AddThresolValue()} className="rightSideContentList">
            <h6> Add Threshold value</h6>
          </div>
        </div>
        <div className="contentList">
          <div className="leftSideContentList">
            <h6>Auto Delete duration for Disapproved content</h6>
            <p>
              Set the duration after which the disapproved content will be
              removed from the data base permenantely.
            </p>
          </div>
          <div onClick={() => SetDuration()} className="rightSideContentList">
            <h6> Set a Duration</h6>
          </div>
        </div>
      </div>
      <h4 className="titleHead avenir-bold">Notification</h4>
      <div className="contentSection">
        <div className="contentList">
          <div className="leftSideContentList">
            <h6>Content Notification</h6>
            <p>
              All the content related notification will fall under this category
            </p>
          </div>
          <div className="rightSideContentList">
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
            />
          </div>
        </div>
        <div className="contentList">
          <div className="leftSideContentList">
            <h6>User Notification</h6>
            <p>
              All the notification related to user invites (invite send, invite
              accepted etc)
            </p>
          </div>
          <div className="rightSideContentList">
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
            />
          </div>
        </div>
        <div className="contentList">
          <div className="leftSideContentList">
            <h6>Security Notification</h6>
          </div>
          <div className="rightSideContentList">
            <FormControlLabel
              control={<IOSSwitch sx={{ m: 1 }} defaultChecked />}
            />
          </div>
        </div>
      </div>
    </Hoc>
  );
};

Setting.propTypes = {};
Setting.defaultProps = {};

const actionCreators = {
  modelToggle
};

export default connect(
  ({ user }) => ({
    modelToggleData: user.modelToggle,
    login: user.login,
    currentUser: user.currentUser
  }),
  actionCreators
)(Setting);
