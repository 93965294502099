import {forwardRef, React, useRef} from 'react';
import Sidebar from '../Sidebar';
import Header from '../Header';
import { Box } from '@mui/material';

const Hoc = forwardRef((props, ref) => {
  const windowSize = useRef([window.innerWidth, window.innerHeight]);
  return (
    <div className="container-fluid">
      <div className="row">
        <Sidebar selected={props?.activeMenu === 'Contents detals' ? 'Contents' : props?.activeMenu} />
        <Box className="col-10 position-relative" sx={{height:windowSize.current[1] - 50 + "px !important", marginBottom: '25px !important'}}>
          <Header activeMenu={props?.activeMenu} subTitle={props?.subTitle} onClick={props?.onClick} role={props.role} ref={ref} />
          <div className='main-contents pr-40'> {props?.children}</div>
        </Box>
      </div>
    </div>
  )
})

export default Hoc;
