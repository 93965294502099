import {
  SET_USER_DATA,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_ERR,
  SEND_EMAIL_REQUEST,
  SEND_EMAIL_SUCCESS,
  SEND_EMAIL_ERR,
  SHOW_DETAIL,
  GET_ALL_USER_REQUEST,
  GET_ALL_USER_SUCCESS,
  GET_ALL_USER_ERR,
  GET_USER_DETAIL_REQUEST,
  GET_USER_DETAIL_SUCCESS,
  GET_USER_DETAIL_ERR,
  GRIDE_TOGGLE,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCESS,
  FORGOT_PASSWORD_ERR,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_ERR,
  VERIFY_USER_REQUEST,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_ERR,
  MODEL_TOGGLE,
  CONTANT_ACTIVE_TAB,
  DRAWER_TOGGLE,
  CHANGE_PASSWORD_REQUEST,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_ERR,
  USER_PROFILE_TOGGLE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  GET_USER_ERR,
  GET_USER_DETAIL_CONTENT_REQUEST,
  GET_USER_DETAIL_CONTENT_SUCCESS,
  GET_USER_DETAIL_CONTENT_ERR,
  GET_SURVERY_USER_LIST_REQUEST,
  GET_SURVERY_USER_LIST_SUCCESS,
  GET_SURVERY_USER_LIST_ERR,
  GET_SURVERY_DETERMINANTS_LIST_REQUEST,
  GET_SURVERY_DETERMINANTS_LIST_SUCCESS,
  GET_SURVERY_DETERMINANTS_LIST_ERR,
  GET_PAYMENT_HISTORY_LIST_REQUEST,
  GET_PAYMENT_HISTORY_LIST_SUCCESS,
  GET_PAYMENT_HISTORY_LIST_ERR,
  SET_SELECTED_SURVEY_DATA,
  GET_ALL_USER_ASSESSMENT_REQUEST,
  GET_ALL_USER_ASSESSMENT_SUCCESS,
  GET_ALL_USER_ASSESSMENT_ERR,
  GET_USER_ASSESSMENT_DETAIL_REQUEST,
  GET_USER__ASSESSMENT_DETAIL_SUCCESS,
  GET_USER_ASSESSMENT_DETAIL_ERR,
  DELETE_USER_ASSESSMENT_REQUEST,
  DELETE_USER_ASSESSMENT_SUCCESS,
  DELETE_USER_ASSESSMENT_ERR,
  GET_QUESTION_LIST_REQUEST,
  GET_QUESTION_LIST_SUCCESS,
  GET_QUESTION_LIST_ERR,
  GET_PROGRAM_LIST_REQUEST,
  GET_PROGRAM_LIST_SUCCESS,
  GET_PROGRAM_LIST_ERR,
  CREATE_PROGRAM_REQUEST,
  CREATE_PROGRAM_SUCCESS,
  CREATE_PROGRAM_ERR,
  UPDATE_PROGRAM_REQUEST,
  UPDATE_PROGRAM_SUCCESS,
  UPDATE_PROGRAM_ERR,
  GET_PROGRAM_DETAILS_REQUEST,
  GET_PROGRAM_DETAILS_SUCCESS,
  GET_PROGRAM_DETAILS_ERR,
} from "../../constants/ActionTypes";
import Cookies from "js-cookie";

const email = Cookies.get("email");
const password = Cookies.get("password");

const initialState = {
  login: {},
  loginLoading: false,
  loginErr: {},
  userName: email ? email : "",
  password: password ? password : "",
  confirmPassword: '',
  status: "login",
  isNewUser: false,
  isLogin: true,
  shoeDetailToggle: false,
  sendEmailData: {},

  listGrideToggle: false,

  allUser: [],
  allUserLoading: false,
  allUserError: false,

  selectedUser: {},
  selectedUserLoading: false,
  selectedUserErr: {},

  forgotPass: {},
  forgotPassLoading: false,
  forgotPassErr: {},

  verifyUser: {},
  verifyUserLoading: false,
  verifyUserErr: {},

  modelToggle: {
    toggle: false,
    modelName: "",
  },

  contentActiveTabeName: "",
  drawerToggleData: "",

  changePassword: {},
  changePasswordLoading: false,
  changePasswordErr: {},

  userProfileModalToggle: {},

  currentUser: {},
  currentUserLoading: false,
  currentUserErr: {},

  deleteUser: {},
  deleteUserLoading: false,
  deleteUserErr: {},

  userDetail: {},
  userDetailLoading: false,
  userDetailErr: {},

  surveyIdList: [],
  surveyIdListLoading: false,
  surveyIdListErr: {},

  determinantList: [],
  userFinalScore: 0,
  determinentsLoading: false,

  paymentHistoryList: [],
  payHistoryLoading: false,

  selectedSurveyData: {},

  userAssessmentList: {},
  userAssessmentLoading: false,

  userAssessmentDetails: {},
  userAssessmentDetailsLoading: false,

  userDeleteLoading: false,
  deleteUserAssessment: null,

  questionList: [],
  totalDetermine: [],
  questionListLoading: false,

  programList: {},
  ProgramListLoading: false,

  saveProgramLoading: false,

  updateProgramLoading: false,

  programDetails: {},
  prgrmDetailsLoading: false,
};

const user = (state = initialState, action = {}) => {
  switch (action.type) {
    case SET_USER_DATA:
      return {
        ...state,
        ...action.content,
      };
    case LOGIN_REQUEST:
      return {
        ...state,
        login: {},
        loginLoading: true,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        login: action.payload,
        loginLoading: false,
      };
    case LOGIN_ERR:
      return {
        ...state,
        loginErr: action.payload,
        loginLoading: false,
      };
    case SEND_EMAIL_REQUEST:
      return {
        ...state,
        sendEmailData: {},
      };
    case SEND_EMAIL_SUCCESS:
      return {
        ...state,
        sendEmailData: action.payload,
      };
    case SEND_EMAIL_ERR:
      return {
        ...state,
        sendEmailData: action.payload,
      };
    case SHOW_DETAIL: {
      return {
        ...state,
        shoeDetailToggle: action.payload,
      };
    }
    case GET_ALL_USER_REQUEST: {
      return {
        ...state,
        allUser: action.payload,
        allUserLoading: true,
      };
    }
    case GET_ALL_USER_SUCCESS: {
      return {
        ...state,
        allUser: action.payload,
        allUserLoading: false,
      };
    }
    case GET_ALL_USER_ERR: {
      return {
        ...state,
        allUserError: action.payload,
        allUserLoading: true,
      };
    }

    case GET_USER_DETAIL_REQUEST: {
      return {
        ...state,
        selectedUser: action.payload,
        selectedUserLoading: true,
      };
    }
    case GET_USER_DETAIL_SUCCESS: {
      return {
        ...state,
        selectedUser: action.payload,
        selectedUserLoading: false,
      };
    }
    case GET_USER_DETAIL_ERR: {
      return {
        ...state,
        selectedUserErr: action.payload,
        selectedUserLoading: true,
      };
    }

    case FORGOT_PASSWORD_REQUEST: {
      return {
        ...state,
        forgotPass: {},
        forgotPassLoading: true,
      };
    }
    case FORGOT_PASSWORD_SUCESS: {
      return {
        ...state,
        forgotPass: action.payload,
        forgotPassLoading: false,
      };
    }
    case FORGOT_PASSWORD_ERR: {
      return {
        ...state,
        forgotPass: action.payload,
        forgotPassLoading: true,
      };
    }
    case VERIFY_USER_REQUEST: {
      return {
        ...state,
        verifyUser: {},
        verifyUserLoading: true,
      };
    }
    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        verifyUser: action.payload,
        verifyUserLoading: false,
      };
    }
    case VERIFY_USER_ERR: {
      return {
        ...state,
        verifyUser: action.payload,
        verifyUserLoading: true,
      };
    }

    case GRIDE_TOGGLE: {
      return {
        ...state,
        listGrideToggle: action.payload,
      };
    }
    case MODEL_TOGGLE: {
      return {
        ...state,
        modelToggle: action.payload,
      };
    }
    case CONTANT_ACTIVE_TAB: {
      return {
        ...state,
        contentActiveTabeName: action.payload,
      };
    }
    case DRAWER_TOGGLE: {
      return {
        ...state,
        drawerToggleData: action.payload,
      };
    }

    case CHANGE_PASSWORD_REQUEST: {
      return {
        ...state,
        changePassword: action.payload,
        changePasswordLoading: true,
      };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return {
        ...state,
        changePassword: action.payload,
        changePasswordLoading: false,
      };
    }
    case CHANGE_PASSWORD_ERR: {
      return {
        ...state,
        changePasswordErr: {},
        changePasswordLoading: false,
      };
    }
    case USER_PROFILE_TOGGLE: {
      return {
        ...state,
        userProfileModalToggle: action.payload,
      };
    }
    case GET_USER_REQUEST: {
      return {
        ...state,
        currentUser: action.payload,
        currentUserLoading: true,
      };
    }
    case GET_USER_SUCCESS: {
      return {
        ...state,
        // surveyIdList: action.payload,
        currentUser: action.payload,
        currentUserLoading: false,
      };
    }
    case GET_USER_ERR: {
      return {
        ...state,
        // surveyIdList: [],
        currentUser: {},
        currentUserLoading: false,
      };
    }
    case DELETE_USER_REQUEST: {
      return {
        ...state,
        deleteUser: action.payload,
        deleteUserLoading: true,
      };
    }
    case DELETE_USER_SUCCESS: {
      return {
        ...state,
        deleteUser: action.payload,
        deleteUserLoading: false,
      };
    }
    case DELETE_USER_ERR: {
      return {
        ...state,
        deleteUser: {},
        deleteUserLoading: false,
      };
    }
    case GET_USER_DETAIL_CONTENT_REQUEST: {
      return {
        ...state,
        userDetail: action.payload,
        userDetailLoading: true,
      };
    }
    case GET_USER_DETAIL_CONTENT_SUCCESS: {
      return {
        ...state,
        userDetail: action.payload,
        userDetailLoading: false,
      };
    }
    case GET_USER_DETAIL_CONTENT_ERR: {
      return {
        ...state,
        userDetailErr: {},
        userDetailLoading: false,
      };
    }
    case GET_SURVERY_USER_LIST_REQUEST: {
      return {
        ...state,
        surveyIdList: action.payload,
        surveyIdListLoading: true,
      };
    }
    case GET_SURVERY_USER_LIST_SUCCESS: {
      return {
        ...state,
        surveyIdList: action.payload,
        surveyIdListLoading: false,
      };
    }
    case GET_SURVERY_USER_LIST_ERR: {
      return {
        ...state,
        surveyIdListErr: {},
        surveyIdListLoading: false,
      };
    }
    case GET_SURVERY_DETERMINANTS_LIST_REQUEST: {
      return {
        ...state,
        determinantList: action.payload.data,
        userFinalScore: action.payload.finalScore,
        determinentsLoading: true,
      };
    }
    case GET_SURVERY_DETERMINANTS_LIST_SUCCESS: {
      return {
        ...state,
        determinantList: action.payload.data,
        userFinalScore: action.payload.finalScore,
        determinentsLoading: false,
      };
    }
    case GET_SURVERY_DETERMINANTS_LIST_ERR: {
      return {
        ...state,
        determinantList: [],
        userFinalScore: 0,
        determinentsLoading: false,
      };
    }
    case GET_PAYMENT_HISTORY_LIST_REQUEST: {
      return {
        ...state,
        paymentHistoryList: action.payload,
        payHistoryLoading: true,
      };
    }
    case GET_PAYMENT_HISTORY_LIST_SUCCESS: {
      return {
        ...state,
        paymentHistoryList: action.payload,
        payHistoryLoading: false,
      };
    }
    case GET_PAYMENT_HISTORY_LIST_ERR: {
      return {
        ...state,
        paymentHistoryList: [],
        payHistoryLoading: false,
      };
    }
    case SET_SELECTED_SURVEY_DATA: {
      return {
        ...state,
        selectedSurveyData: action.payload,
      };
    }
    case GET_ALL_USER_ASSESSMENT_REQUEST: {
      return {
        ...state,
        userAssessmentList: action.payload,
        userAssessmentLoading: true,
      };
    }
    case GET_ALL_USER_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        userAssessmentList: action.payload,
        userAssessmentLoading: false,
      };
    }
    case GET_ALL_USER_ASSESSMENT_ERR: {
      return {
        ...state,
        userAssessmentList: {},
        userAssessmentLoading: false,
      };
    }
    case GET_USER_ASSESSMENT_DETAIL_REQUEST: {
      return {
        ...state,
        userAssessmentDetails: action.payload,
        userAssessmentDetailsLoading: true,
      };
    }
    case GET_USER__ASSESSMENT_DETAIL_SUCCESS: {
      return {
        ...state,
        userAssessmentDetails: action.payload,
        userAssessmentDetailsLoading: false,
      };
    }
    case GET_USER_ASSESSMENT_DETAIL_ERR: {
      return {
        ...state,
        userAssessmentDetails: {},
        userAssessmentDetailsLoading: false,
      };
    }
    case DELETE_USER_ASSESSMENT_REQUEST: {
      return {
        ...state,
        userDeleteLoading: true,
      };
    }
    case DELETE_USER_ASSESSMENT_SUCCESS: {
      return {
        ...state,
        userDeleteLoading: false,
        deleteUserAssessment: "success",
      };
    }
    case DELETE_USER_ASSESSMENT_ERR: {
      return {
        ...state,
        userDeleteLoading: false,
        deleteUserAssessment: "failed",
      };
    }
    case GET_QUESTION_LIST_REQUEST: {
      return {
        ...state,
        questionList: action.payload,
        questionListLoading: true,
      };
    }
    case GET_QUESTION_LIST_SUCCESS: {
      let countDeterminArray = [];
      action.payload &&
        action.payload?.map((obj, index) => {
          if (
            countDeterminArray.filter(
              (subObj) => subObj.name === obj.Determinant
            ).length === 0
          ) {
            countDeterminArray.push({
              name: obj.Determinant,
              isFillUp: false,
            });
          }
          return "";
        });
      return {
        ...state,
        questionList: action.payload,
        totalDetermine: countDeterminArray,
        questionListLoading: false,
      };
    }
    case GET_QUESTION_LIST_ERR: {
      return {
        ...state,
        questionList: {},
        questionListLoading: false,
      };
    }
    case GET_PROGRAM_LIST_REQUEST: {
      return {
        ...state,
        programList: action.payload,
        ProgramListLoading: true,
      };
    }
    case GET_PROGRAM_LIST_SUCCESS: {
      return {
        ...state,
        programList: action.payload,
        ProgramListLoading: false,
      };
    }
    case GET_PROGRAM_LIST_ERR: {
      return {
        ...state,
        programList: {},
        ProgramListLoading: false,
      };
    }
    case CREATE_PROGRAM_REQUEST: {
      return {
        ...state,
        saveProgramLoading: true,
      };
    }
    case CREATE_PROGRAM_SUCCESS: {
      return {
        ...state,
        saveProgramLoading: false,
      };
    }
    case CREATE_PROGRAM_ERR: {
      return {
        ...state,
        saveProgramLoading: false,
      };
    }
    case UPDATE_PROGRAM_REQUEST: {
      return {
        ...state,
        updateProgramLoading: true,
      };
    }
    case UPDATE_PROGRAM_SUCCESS: {
      return {
        ...state,
        updateProgramLoading: false,
      };
    }
    case UPDATE_PROGRAM_ERR: {
      return {
        ...state,
        updateProgramLoading: false,
      };
    }
    case GET_PROGRAM_DETAILS_REQUEST: {
      return {
        ...state,
        prgrmDetailsLoading: true,
        programDetails: action.payload,
      };
    }
    case GET_PROGRAM_DETAILS_SUCCESS: {
      return {
        ...state,
        prgrmDetailsLoading: false,
        programDetails: action.payload,
      };
    }
    case GET_PROGRAM_DETAILS_ERR: {
      return {
        ...state,
        prgrmDetailsLoading: false,
        programDetails: {},
      };
    }
    default:
      return state;
  }
};

export default user;
