import React, { useEffect, useState } from "react";
import TableCell from "@mui/material/TableCell";
import Option from "@mui/icons-material/MoreHorizOutlined";
import TableRow from "@mui/material/TableRow";
import Woman from "../../assets/images/woman.png";
import "./usermanagetable.scss";
import Avatar from "@mui/material/Avatar";
import { connect } from "react-redux";

import {
  showDetail,
  userDetail,
  getUserDetails,
  getAllAssessmentUserList,
  getAssessmentUserDetails,
} from "../../redux/action/user";
import BaseTable from "../BaseTable/BaseTable";
import { Tooltip } from "@mui/material";
import { useLocation } from "react-router";

const UserListTable = (props) => {
  const [filters, setFilters] = useState(filterData);
  const location = useLocation();
  const list = props.userAssessmentList || {};
  const [paginationParams, setPaginationParams] = useState({
    currentPage: 1,
    limit: 10,
    total: 1,
    totalRecords: 0,
  });


  useEffect(() => {
    if (props.userAssessmentList?.pagination) {
      setPaginationParams((prev) => {
        if (
          prev.currentPage === props.userAssessmentList.pagination.currentPage &&
          prev.limit === props.userAssessmentList.pagination.pageSize &&
          prev.total === props.userAssessmentList.pagination.totalPages &&
          prev.totalRecords === props.userAssessmentList.pagination.totalUsers
        ) {
          return prev;
        }

        return {
          currentPage: props.userAssessmentList.pagination.currentPage || 1,
          limit: props.userAssessmentList.pagination.pageSize || 10,
          total: props.userAssessmentList.pagination.totalPages || 1,
          totalRecords: props.userAssessmentList.pagination.totalUsers || 0,
        };
      });
    }
  }, [props.userAssessmentList]);




  useEffect(() => {
    const newArr = filterData.map(el => ({
      ...el,
      value: ''
    }));
    setFilters(newArr);
  }, [location.pathname]);


  const handleFilterClick = (updatedFilters) => {
    setPaginationParams((prev) => ({
      ...prev,
      currentPage: 1,
    }));
    setFilters(updatedFilters);
  };

  const UserDetails = (userid, toggle, orgUserId) => {
    localStorage.setItem("userAssessmentId", userid);
    // props.getUserDetails(orgUserId);
    // props.userDetail(userid);
    props.showDetail(toggle);
    // props.getAssessmentUserDetails(userid);
  };

  useEffect(() => {
    localStorage.setItem("userRole", props?.role);
  }, [props?.role]);

  const headers = [
    { label: "Name", align: "center" },
    { label: "Email", align: "center" },
    // { label: "Mobile Number", align: "center" },
    { label: "Total Assessments", align: "center" },
    { label: "Current Plan/Subscription", align: "center" },
    { label: "Member Type", align: "center" },
    { label: "Status", align: "center" },
  ];

  const renderRows = () => {
    return (
      list &&
      list.userList &&
      list.userList?.map((row, index) => {
        return (
          <TableRow
            key={index}

            className="table-row"
            sx={{
              "&:last-child td, &:last-child th": { border: 0 },
              "& th": {
                color: "#fff",
                borderBottom: "1px solid #273334",
              },
              "& td": {
                borderBottom: "1px solid #273334",
              },
            }}
          >
            <TableCell
              component="th"
              scope="row"
              align="left"
              className="text-white"
              onClick={() => UserDetails(row?.UserID, true, 0)}
            >
              <div className="d-flex overEffect align-items-center">
                <Avatar className="mx-3" alt="Travis Howard" src={Woman} />
                <div>
                  <span className="nameText">
                    {row?.Name ? row?.Name : "Robot"}{" "}
                  </span>
                </div>
              </div>
            </TableCell>

            <TableCell align="center" className="text-white qualification">
              {row?.Email ? row?.Email : "Therapist"}
            </TableCell>
            {/* <TableCell align="center" className="expirence">
              {row?.MobileNumber ? row?.MobileNumber : 0}
            </TableCell> */}
            <TableCell align="center" className="text-white totalContent">
              {row?.TotalAssessments ? row?.TotalAssessments : 0}
            </TableCell>
            <TableCell align="center" className="text-white">
              {row?.CurrentPlan?.some(
                (plan) => plan?.programSubscriptionId !== null
              ) ||
                (row?.CurrentSubscription?.programSubscriptionId !== null &&
                  row?.CurrentSubscription?.name) ? (
                [
                  ...(row?.CurrentPlan || []),
                  row?.CurrentSubscription?.programSubscriptionId !== null
                    ? row?.CurrentSubscription
                    : null,
                ]
                  .filter(Boolean)
                  .map((ele, index) => {
                    const formatDate = (date) => {
                      const parsedDate = new Date(date);
                      return isNaN(parsedDate)
                        ? ""
                        : parsedDate.toLocaleDateString("en-GB");
                    };

                    const formattedStartDate = ele?.purchasedDate
                      ? formatDate(ele?.purchasedDate)
                      : "";
                    const formattedEndDate = ele?.expirationDate
                      ? formatDate(ele?.expirationDate)
                      : "";
                    const name = (ele?.name !== null && ele?.name + ", ") || "";

                    return (
                      <span key={index}>
                        <Tooltip
                          title={`Start Date: ${formattedStartDate}, End Date: ${formattedEndDate}`}
                          arrow
                        >
                          <span
                            style={{
                              cursor: "pointer",
                              textDecoration: "underline",
                            }}
                          >
                            {name}
                          </span>
                        </Tooltip>
                      </span>
                    );
                  })
              ) : (
                <span>-</span>
              )}
            </TableCell>
            <TableCell align="center" className="text-white">
              <p
                className={
                  row?.isPaid == 1 ? "user-active" : "user-inactive"
                }
              >
                <p className="user-active-on"> </p>
                {row?.isPaid == 1 ? "Paid" : "Free"}
              </p>
            </TableCell>
            <TableCell align="center" className="text-white">
              {row?.Status === "A" ? "Active" : "Deactivated"}
            </TableCell>
          </TableRow>
        );
      })
    );
  };

  return (
    <div>
      <BaseTable
        headers={headers}
        rows={list?.userList || []}
        renderRows={renderRows}
        fetchData={props.getAllAssessmentUserList}
        isLoading={props.userAssessmentLoading}
        showPagination={paginationParams.totalRecords > 0}
        paginationParams={paginationParams}
        setPaginationParams={setPaginationParams}
        currentPage={paginationParams.currentPage}
        limit={paginationParams.limit}
        handleFilterClick={handleFilterClick}
        filters={filters}
        isFilter={true}
        height={"500px"}
      />
    </div>
  );
};

UserListTable.propTypes = {};
UserListTable.defaultProps = {};

const actionCreators = {
  showDetail,
  getAllAssessmentUserList,
  userDetail,
  getUserDetails,
  getAssessmentUserDetails,
};

export default connect(
  ({ user }) => ({
    allUsers: user.allUser,
    allUserLoading: user.allUserLoading,
    userAssessmentLoading: user.userAssessmentLoading,
    userAssessmentList: user.userAssessmentList,
  }),
  actionCreators
)(UserListTable);

const filterData = [
  { id: 1, label: "Name", value: "", type: "input", name: "name" },
  { id: 2, label: "Email", value: "", type: "input", name: "email" },
  {
    id: 3,
    label: "Status",
    name: "status",
    value: "",
    type: "multi-select",
    dropArr: [
      {
        value: "Active",
        label: "Active",
      },
      {
        value: "Deactive",
        label: "Deactive",
      },
    ],
  },
  {
    id: 4,
    label: "Member Type",
    name: "isPaid",
    value: "",
    type: "multi-select",
    dropArr: [
      {
        value: "free",
        label: "Free",
      },
      {
        value: "paid",
        label: "Paid",
      },
    ],
  },
  {
    id: 5,
    name: "totalAssessments",
    label: "Total Assessments",
    value: "",
    type: "input-number",
  },
  // {
  //   id: 6,
  //   name: "mobileNumber",
  //   label: "Mobile Number",
  //   value: "",
  //   type: "input-number",
  // },
];
