// AnalysisTab.js
import { Avatar, Card, CardMedia, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { modelToggle, userProfileModalToggle } from "../../redux/action/user";
import Man from "../../assets/images/woman.png";
import CircleIcon from "@mui/icons-material/Circle";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import { IconPath } from "../../constants/IconPath";

const UserRemove = (props) => {
  const removeUser = () => {
    // alert('are you sure you want to delete!');
    props.modelToggle(true, "delete_user");
    // props.showDetail(false);
  };

  return (
    <Card className="user-details-box">
      <div className="user-card">
        <div className="user-card-inner-1">
          <CardMedia>
            <Avatar
              alt="Travis Howard"
              src={
                props?.data?.contentUserProfile?.Photo
                  ? props?.data?.contentUserProfile?.Photo
                  : Man
              }
            />
          </CardMedia>
          <Typography variant="h6" sx={{ textTransform: "capitalize" }}>
            {props.role == 1
              ? `${props.userAssessmentDetails?.Fname || ""} ${props.userAssessmentDetails?.Lname || ""}`.trim()
              : props?.data?.contentUser?.Fullname || ""}

            {/* <CircleIcon
              sx={
                props?.userAssessmentDetails?.Status === "A"
                  ? { width: "10px", color: "#40CE79" }
                  : { width: "10px", color: "red" }
              }
            /> */}
            <p className="sub-inner-child-p">
              {props?.data?.contentUserProfile?.Gender}{" "}
              <b>{props?.data?.contentUserProfile?.Expertise}</b>
            </p>

          </Typography>
          <div className="user-card-contact">
            <PhoneCallbackIcon sx={{ width: "18px" }} />
            <span>
              {props.role == 1
                ? props.userAssessmentDetails?.CountryCode
                : props?.data?.contentUser?.CountryCode || "+91"}
            </span>{" "}
            {props.role == 1
              ? props.userAssessmentDetails?.MobileNo
              : props?.data?.contentUser?.MobileNo || "0000000000"}
          </div>
        </div>
        {props?.activePage == "UserProfileModal" && (
          <div>
            <img
              onClick={() => props.userProfileModalToggle(false, null)}
              src={require("../../assets/images/x.png")}
              style={{ cursor: "pointer" }}
            />
          </div>
        )}
        {props?.activePage == "UserManagement" && (
          <div>
            <button
              onClick={() => removeUser()}
              className="avenir-bold editBtnProfile"
            >
              {props?.userAssessmentDetails?.Status === "A" ? "Deactivate" : "Activate"}
            </button>
          </div>
        )}
      </div>
    </Card>
  );
};

UserRemove.propTypes = {};
UserRemove.defaultProps = {};

const actionCreators = {
  userProfileModalToggle,
  modelToggle,
};

export default connect(
  ({ user, content }) => ({
    selectedUserData: user.selectedUser,
    selectedUserLoading: user.selectedUserLoading,
    userProfileModal: user.userProfileModalToggle,
    modelToggleData: user.modelToggle,
    userDetailList: user.userDetail,
    userDetailLoading: user.userDetailLoading,
    userAssessmentDetails: user.userAssessmentDetails,
  }),
  actionCreators
)(UserRemove);
