import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";
import { connect } from "react-redux";
import StarsRating from "stars-rating";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  InputLabel,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import closex from "../../../assets/images/x.png";
import heartTick from "../../../assets/images/heart-tick.png";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import GradientSVG from "./gradientSVG";
import {
  getDeterminants,
  updateSuggestion,
  updateReference,
} from "../../../redux/action/user";
import bgImage from "../../../assets/images/result_details_top.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./about.scss";

const UserScore = forwardRef((props, ref) => {
  const [open, setOpen] = useState(false);
  const [modalData, setModalData] = useState({});
  const [starValue, setStarValue] = useState(0);
  const [expandedList, setExpandedList] = useState([]);
  const [completed, setCompleted] = useState([]);

  useImperativeHandle(ref, () => ({
    handleBackButton: () => props.setActiveAboutInfo("viewData"),
  }));

  const setImageBase64 = async () => {
    // const getBase64StringFromDataURL = (dataURL) =>
    //   dataURL.replace("data:", "").replace(/^.+,/, "");

    const image = await (await fetch("/demo-share-image.png")).blob();

    const reader = new FileReader();

    reader.onloadend = () => {
      // Convert to Base64 string
      // const base64 = getBase64StringFromDataURL(reader.result);
    };
    reader.readAsDataURL(image);
  };

  useEffect(() => {
    if (props.user?.selectedSurveyData) {
      props.getDeterminants(props.user?.selectedSurveyData?.SurveyId);
    }
    setImageBase64();
  }, []);

  useEffect(() => {
    if (props.user.determinantList && props.user.determinantList.length > 0) {
      setStarValue(
        props.user.userFinalScore >= 90
          ? 5
          : props.user.userFinalScore >= 78 && props.user.userFinalScore <= 89
            ? 4
            : props.user.userFinalScore >= 68 && props.user.userFinalScore <= 77
              ? 3
              : props.user.userFinalScore >= 50 && props.user.userFinalScore <= 67
                ? 2
                : 1
      );
      setExpandedList(
        props.user.determinantList && [props.user?.determinantList[0]?.ID]
      );
      const progress100 = getSuggestionsWithCompelted(
        props.user?.determinantList
      );
      setCompleted(progress100);
    }
  }, [props.user.determinantList]);

  const getSuggestionsWithCompelted = (suggestions) => {
    return suggestions.flatMap((item) =>
      item.suggestion
        .filter((s) => s.progress === 100)
        .map((s) => s.suggestionID)
    );
  };

  const onSubmitQuestion = (tempModalData) => {
    setOpen(!open);
    setModalData(tempModalData);
    if (tempModalData?.suggestionID) {
      props.updateSuggestion(props?.user._id, tempModalData.suggestionID);
    }
  };

  const getGoalStatus = (suggestionObj) => {
    if (completed.includes(suggestionObj.suggestionID)) {
      return {
        color: "#709640",
        showProgress: false,
        icon: "completed-icon",
        statusClass: "complete",
      };
    } else {
      return {
        color: "#ffd781",
        showProgress: false,
        icon: "archived-icon",
        statusClass: "archived",
      };
    }
  };

  return (
    <div className="result-body result-body">
      {props.user.determinentsLoading ? (
        <h1>Loading ...</h1>
      ) : (
        <div
          className="result-body__fixed bg-transparent"
          style={{
            // height: "calc(100vh - 280px)",
            overflow: "auto",
          }}
        >
          <div className="gridContainer">
            <div
              // className="flex-column"
              style={{ display: "flex", marginTop: "20px" }}
            >
              <div className="chart-block">
                <div className="chart-title">
                  <h2 className="white-color avenir-bold">Vitality Score</h2>
                </div>
                <GradientSVG />
                <div className="chart-detail">
                  <div className="chart-detail__chart">
                    <CircularProgressbar
                      value={props.user?.userFinalScore || 0}
                      strokeWidth={8}
                      text={`${props.user.userFinalScore}`}
                      styles={buildStyles({
                        rotation: 1 / 2,
                        pathColor: `url(#hello)`,
                        trailColor: "#264448",
                        textColor: "#fff",
                      })}
                      style={{
                        borderRadius: "50%",
                      }}
                    />
                  </div>
                </div>
                <div className="score-result">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <StarsRating
                      count={5}
                      value={starValue}
                      edit={false}
                      size={35}
                      color2={"#ffd700"}
                    />
                  </div>
                </div>

                <div style={{ alignSelf: "center", padding: "50px" }}>
                  <InputLabel
                    style={{
                      cursor: "pointer",
                      paddingRight: "20px",
                      marginTop: "auto",
                      paddingBottom: "10px",
                      backgroundColor: "#E29C2E",
                      color: "#fff",
                      width: "100%",
                      borderRadius: "8px",
                      padding: "10px",
                    }}
                    onClick={() => {
                      props.setActiveAboutInfo("assessment");
                    }}
                  >
                    Review My Answer
                  </InputLabel>
                </div>
              </div>

              <div className="result-block">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "end",
                    padding: "10px",
                    // marginBottom: "5px",
                    "&> p": {
                      "&.MuiTypography-root": {
                        position: "relative",
                        fontSize: "14px !important",
                        "&:before": {
                          content: '""',
                          position: "absolute",
                          width: "10px",
                          height: "10px",
                          left: -15,
                          top: 5,
                          backgroundColor: "#fff",
                          borderRadius: "50%",
                        },
                        "&:nth-of-type(1)": {
                          mr: 3,
                          "&:before": {
                            backgroundColor: "#ffd781",
                          },
                        },
                        "&:nth-of-type(2)": {
                          "&:before": {
                            backgroundColor: "#709640",
                          },
                        },
                      },
                    },
                  }}
                >
                  <Typography sx={{ color: "#fff !important" }}>Archived</Typography>
                  <Typography sx={{ color: "#fff" }}>Completed</Typography>
                </Box>
                {props.user.determinantList &&
                  props.user.determinantList.length > 0 && (
                    <div>
                      {/* <div className="top-result-details">
                        <img src={bgImage} alt={bgImage} />
                        <div className="ab-top-result-details">
                          <p>score by category</p>
                        </div>
                      </div> */}
                      <div
                        className="inner-bg-result"
                        style={{ paddingTop: "10px" }}
                      >
                        {/* <div className="sub-header">
                          <p>Click on each tip to learn more</p>
                        </div> */}
                        <div>
                          {props.user.determinantList &&
                            props.user.determinantList.map((obj, index) => {
                              return (
                                <Accordion
                                  expanded={expandedList.includes(obj?.ID)}
                                  key={index}
                                >
                                  <AccordionSummary
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1bh-content"
                                    id="panel1bh-header"
                                    onClick={() => {
                                      setExpandedList((prev) => {
                                        const temp = Array.from(prev);
                                        if (temp.includes(obj.ID)) {
                                          temp.splice(temp.indexOf(obj.ID), 1);
                                        } else {
                                          temp.push(obj.ID);
                                        }

                                        return temp;
                                      });
                                    }}
                                  >
                                    <Typography
                                      sx={{ flexShrink: 0 }}
                                      style={{
                                        display: "flex",
                                        gap: 10,
                                        alignItems: "center",
                                      }}
                                    >
                                      <div className="image-container">
                                        {obj?.Icon && (
                                          <img
                                            src={require(`../../../assets/images/determine${obj?.Icon}`)}
                                            height={50}
                                            minWidth={50}
                                          />
                                        )}
                                      </div>
                                      <div>{obj.Determinant}</div>
                                    </Typography>
                                    <Typography
                                      className="avenir-bold"
                                      sx={{ color: "text.secondary" }}
                                      style={{ marginRight: "10px" }}
                                    >
                                      Scored - {obj?.score && obj.score}
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    {obj.isGetSuggestion ? (
                                      <>
                                        {/* <div className="yellow-font tooltip-title">
                                          Tips to Improve
                                        </div> */}
                                        <Box sx={{ ...GoalListing }}>
                                          <ul>
                                            {obj.suggestion &&
                                              obj.suggestion.length > 0 &&
                                              obj.suggestion.map(
                                                (suggestionObj, subIndex) => {
                                                  const goalStatus =
                                                    getGoalStatus(
                                                      suggestionObj
                                                    );
                                                  return (
                                                    <li>
                                                      <Box className="ContentWrap">
                                                        <a
                                                          className="Suggestion"
                                                          style={{
                                                            color:
                                                              goalStatus.color,
                                                          }}
                                                          onClick={() => {
                                                            if (
                                                              onSubmitQuestion
                                                            ) {
                                                              onSubmitQuestion(
                                                                suggestionObj
                                                              );
                                                            }
                                                          }}
                                                        >
                                                          {
                                                            suggestionObj.Suggestion
                                                          }
                                                        </a>
                                                      </Box>
                                                    </li>
                                                  );
                                                }
                                              )}
                                          </ul>
                                        </Box>
                                      </>
                                    ) : (
                                      <ul>
                                        <p className="cus-label">
                                          {obj.suggestion}
                                        </p>
                                      </ul>
                                    )}
                                  </AccordionDetails>
                                </Accordion>
                              );
                            })}
                        </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
          </div>
        </div>
      )}
      <Modal open={open}>
        <Box sx={style} className="inner-success-modal w-95">
          <div className="ab-close-pop-wrap">
            <img
              src={closex}
              alt={closex}
              onClick={() => onSubmitQuestion({})}
            />
          </div>
          <div className="out-card-box-wrap">
            <div className="left-image-wrap">
              <img src={heartTick} alt={heartTick} />
            </div>
            <div className="right-desc-wrap">
              <b className="avenir-bold">{modalData.Suggestion}</b>
              <p>{modalData.DeterminantName}</p>
            </div>
          </div>
          <div className="inner-body-pop-up-wrap">
            <p>{modalData.Blurb}</p>
            <p>
              Evidence :
              <a
                href={modalData.ReferenceLink}
                target="_blank"
                rel="noreferrer"
                onClick={() => {
                  props.updateReference(props.user._id, modalData.suggestionID);
                }}
              >
                {modalData.ReferenceLink}
              </a>
            </p>
          </div>
        </Box>
      </Modal>
    </div>
  );
});

UserScore.propTypes = {};
UserScore.defaultProps = {};

const actionCreators = {
  getDeterminants,
  updateSuggestion,
  updateReference,
};

export default connect(
  ({ user }) => ({
    user,
  }),
  actionCreators,
  null,
  { forwardRef: true }
)(UserScore);

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "400px",
  bgcolor: "#fff",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};

const GoalListing = {
  ul: {
    li: {
      position: "relative",
      backgroundColor: "#112225",
      pr: 1,
      pt: "6px !important",
      display: "flex",
      width: "100%",
      textDecoration: "none !important",
      "&:before": {
        display: "none !important",
      },
      ".IconWrap": {
        width: "4%",
        pt: 0,
        textAlign: "center",
        svg: {
          fontSize: "1.2rem",
          path: {
            fill: "#ffd781",
          },
        },
      },
      ".ContentWrap": {
        // paddingLeft: "10px",
        width: "95%",
      },
      "&:before": {
        display: "none",
      },
      "&:after": {
        content: '""',
        position: "absolute",
        left: "-10px",
        height: "100%",
        width: "9px",
        borderRadius: "10px",
        backgroundColor: "transparent",
        top: 0,
        bottom: 0,
      },

      "&.cancel": {
        "&:after": {
          backgroundColor: "#ffd781",
        },
      },
      "&.complete": {
        "&:after": {
          backgroundColor: "#709640",
        },
      },
      ".Suggestion": {
        mr: 2,
        fontSize: "0.9rem",
        lineHeight: 1.2,
        textDecoration: "none !important",
      },
    },
  },
};
