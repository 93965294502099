import React, { useEffect } from "react";
import "./signupSection.scss";
import { connect } from "react-redux";
import TextBox from "../../atoms/textbox";
import { toast } from "react-toastify";
import ButtonInput from "../../atoms/button";
import sms from "../../assets/images/sms.png";
import backButton from "../../assets/images/arrow-left.svg";

import { updateUserData, forgotPassword } from "../../redux/action/user";


const LoginForgotPassword = (props) => {
  const emailChange = (value) => {
    props.updateUserData({
      ...props.user,
      email: value,
    });
  };

  const alertMsg = () => {
    toast("Please check your email!");
  }

  const gotoOTP = async () => {
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,7}$/i.test(props?.user?.email)) {
      toast("Please Enter Valid email");
      return false;
    } else {
      try {
        await props.forgotPassword(props.user.email, alertMsg);
        props.updateUserData({
          ...props.user,
          status: "confirmPassword",
        });
      } catch (error) {
        toast(error?.response?.data?.message || "Something went wrong");
      }
    }
  };

  const gotoLogin = () => {
    props.updateUserData({
      ...props.user,
      status: "login",
      isNewUser: false,
    });
  };

  return (
    <div className="login_form">
      <div className="container-fluid">
        <div className="row">
          <h2 className="title">Don't worry We're here to help you!</h2>
          <p className="subTitle">
            Enter your email you used to join and we will send you the
            verification code.
          </p>
        </div>
        <TextBox
          placeholder="Email Address"
          value={props.user.email}
          valueChange={emailChange}
          prefix={sms}
        />
        <div>
          <ButtonInput text="SEND" onSubmit={gotoOTP} />
          <ButtonInput
            text="Back"
            class="back-button"
            onSubmit={gotoLogin}
            iCon={backButton}
          />
        </div>
      </div>
    </div>
  );
};

LoginForgotPassword.propTypes = {};
LoginForgotPassword.defaultProps = {};

const actionCreators = {
  updateUserData,
  forgotPassword
};

export default connect(
  ({ user }) => ({
    user,
    forgotPass: user.forgotPass
  }),
  actionCreators
)(LoginForgotPassword);
